const initialState = {
  isAuthenticated: false,
  auth: {},
  error: null,
  token: null,
};
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
const authAimmReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, ...action.payload, isAuthenticated: false, error: null };
    case LOGIN_SUCCESS:
      return { ...action.payload, isAuthenticated: true, auth: action.payload, error: null };
    case SET_TOKEN:
      return { ...state, isAuthenticated: true, token: action.payload };
    case LOGOUT:
      return { ...state, isAuthenticated: false, error: null };
    default:
      return state;
  }
};

export default authAimmReducer;
