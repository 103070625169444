import { AuthInstance } from '../../providers/axiosInstance';
import { getCurrentBaseUrl } from '../../utils/common';
import Cookies from 'js-cookie';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const login = () => {
  // export const login = (userType: any) => {
  const host = getCurrentBaseUrl();
  const baseUrl = `${host}`;
  const defaultPage = '/admin/week-at-a-glance/in-house';
  const redirectUrl = `${baseUrl}${defaultPage}`;
  const backendUrl = `${process.env.REACT_APP_AUTH_URL}/spa/auth/${process.env.REACT_APP_NAME_APP}/login?redirect=${redirectUrl}`;
  window.location.href = backendUrl;
  // localStorage.setItem('userType', userType);
};

// export const loginFailure = (error) => ({
//   type: LOGIN_FAILURE,
//   payload: error
// });

// export const logout = () => ({
//   type: LOGOUT,
// });
export const logout = () => {
  const host = getCurrentBaseUrl();
  const redirectUrl = `${host}`;
  const backendUrl = `${process.env.REACT_APP_AUTH_URL}/spa/auth/${process.env.REACT_APP_NAME_APP}/logout?returnTo=${redirectUrl}`;
  window.location.href = backendUrl;
};

export const validateSession = async () => {
  try {
    const res = await AuthInstance.get(`/spa/auth/${process.env.REACT_APP_NAME_APP}/validate`);
    const encodedJwt = res.data?.token;
    Cookies.set('token', encodedJwt);
    Cookies.set('otoken', encodedJwt);
    return true;
  } catch (error) {
    // dispatch({ type: SET_TOKEN, payload: '' });
    return false;
  }
};

const addTokenAuthorization = token => {
  if (token)
    [AuthInstance].forEach(api => {
      api.interceptors.request.use(config => {
        if (!config?.headers)
          config.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          };
        config.headers['Authorization'] = `Bearer ${token}`;
        config.withCredentials = false;
        return config;
      });
    });
};
