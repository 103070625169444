const initialState = {
  addNewSource: false,
  editSource: false,
  deleteSource: false,
  deleteSuccess: false,
  updateSuccess: false,
  addedSuccess: false,
  changeIsSub: [],
  JOB_TYPES: [],
  inhouseEmployeeLists: [],
  totalWeeklyIcome: '',
  source: {
    companyIncomeSourceId: '',
    incomeSourceName: '',
    isSubcontractorIncomeSource: false,
    weeklyIncomeAmount: '',
    monthlyIncomeAmount: '',
    rowVersion: '',
    strRowVersion: '',
  },
  sourceList: [],
  sourceCogsList: [],
  sourceListNoContractor: [],
  sourceListContractor: [],
  totalCogsWeekly: null,
};

let nextState = null;

const sourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESETSOURCE':
      return initialState;
    case 'STOPEDITSOURCE':
      return { ...state, editSource: false, addNewSource: false };
    case 'ADDSOURCE':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.addNewSource = true;
      nextState.editSource = false;
      for (const object in nextState.source) {
        nextState.source[object] = '';
      }
      return nextState;
    case 'ADDSOURCETOLIST':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceList.push(action.payload);
      var sum = 0;
      nextState.sourceList.map(src => (sum = sum + parseInt(src.weeklyIncomeAmount)));
      nextState.totalWeeklyIcome = sum;
      nextState.addedSuccess = true;
      return nextState;
    case 'GETSOURCE':
      nextState = JSON.parse(JSON.stringify(state));
      for (const object in nextState.source) {
        nextState.source[object] = action.payload[object] == null ? '' : action.payload[object];
      }
      return nextState;
    case 'GETSOURCELIST':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceList = action.payload;
      var sum = 0;
      var array = [];
      action.payload
        .filter(job =>
          job.divisionType ? job.divisionType == 1 : !job.isSubcontractorIncomeSource
        )
        .map((data, idx) =>
          array.push({
            jobTypeId: data.companyIncomeSourceCogsId,
            jobTypeDesc: data.incomeSourceName,
            companyIncomeSourceId: data.companyIncomeSourceId,
          })
        );
      nextState.sourceList.map(src => (sum = sum + src.weeklyIncomeAmount));
      nextState.totalWeeklyIcome = sum;
      nextState.addedSuccess = false;
      nextState.updateSuccess = false;
      nextState.deleteSuccess = false;
      nextState.deleteSource = false;
      nextState.JOB_TYPES = array;
      return nextState;
    case 'GETSOURCECOGS':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceCogsList = action.payload;
      return nextState;
    case 'UPDATESUCCESS':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.updateSuccess = action.payload;
      return nextState;
    case 'SOURCELISTUPDATE':
      nextState = JSON.parse(JSON.stringify(state));
      var sum = 0;
      nextState.sourceList = nextState.sourceList.map(src => {
        if (src.companyIncomeSourceId === action.payload.companyIncomeSourceId) {
          src = action.payload;
        }
        sum = sum + parseInt(src.weeklyIncomeAmount);
        return src;
      });
      nextState.totalWeeklyIcome = sum;
      return nextState;
    case 'EDITSOURCE':
      nextState = JSON.parse(JSON.stringify(state));
      for (const object in nextState.source) {
        nextState.source[object] = action.payload[object] == null ? '' : action.payload[object];
      }
      nextState.editSource = true;
      nextState.addNewSource = false;
      return nextState;
    case 'DELETESOURCE':
      return {
        ...state,
        addNewSource: false,
        editSource: false,
        deleteSource: true,
      };
    case 'UPDATESOURCE':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (action.payload.key === 'isSubcontractorIncomeSource')
        action.payload.value = action.payload.value === 'true' ? true : false;
      nextState.source[action.payload.key] = action.payload.value;
      return nextState;
    case 'CONFIRMDELETESOURCE':
      nextState = JSON.parse(JSON.stringify(state));
      var sum = 0;
      nextState.sourceList = nextState.sourceList.filter(src => {
        sum = sum + parseInt(src.weeklyIncomeAmount);
        return src.companyIncomeSourceId !== action.payload.companyIncomeSourceId;
      });
      nextState.totalWeeklyIcome = sum;
      nextState.deleteSuccess = true;
      return nextState;
    case 'CHECKSUB':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceList = nextState.sourceList.filter(src => {
        if (src.companyIncomeSourceId === action.payload.key) {
          src.isSubcontractorIncomeSource = !src.isSubcontractorIncomeSource;
        }
        return src;
      });
      return nextState;
    case 'UPDATESTATUS':
      nextState = JSON.parse(JSON.stringify(state));
      nextState[action.payload.key] = action.payload.value;
      return nextState;
    case 'SOURCELISTWITHOUTCONTRACTOR':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceListNoContractor = action.payload;
      return nextState;
    case 'SOURCELISTWITHSUBCONTRACTOR':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.sourceListContractor = action.payload;
      return nextState;
    case 'SETINHOUSEEMPLOYEELISTS':
      nextState = JSON.parse(JSON.stringify(state));
      var array = [];
      action.payload.map((data, idx) =>
        array.push({
          employeeId: data.companyInHouseIncomeSourceEmployeeId,
          employeeName: `${data.firstName} ${data.lastName}`,
          regRate: data.hourlyWageRate,
          otRate: data.hourlyWageRate * 1.5,
          dotRate: data.hourlyWageRate * 2,
          companyIncomeSourceId: data.companyIncomeSourceId,
          isCrewChief: data.isCrewChief,
          hourlyWageRate: data.hourlyWageRate,
          isActive: data.isActive,
          averageHoursPerWeek: data.averageHoursPerWeek,
          totalPay: data.totalPay,
        })
      );
      nextState.inhouseEmployeeLists = array;
      return nextState;
    default:
      return state;
  }
};

export default sourceReducer;
