import { combineReducers } from 'redux';

import authReducer from './authentication';
import commonReducer from './common';
import companyInfoReducer from './companyInfo';
import customerSearchReducer from './customerSearch';
import errorReducer from './error';
import gpmdReducer from './GPMD';
import sourceReducer from './source';
import plSummeryReducer from './PLSummary';
import settingsReducer from './systemSettings';
import authAimmReducer from './authAimm';

const reducers = combineReducers({
  auth: authReducer,
  authAimm: authAimmReducer,
  error: errorReducer,
  gpmd: gpmdReducer,
  source: sourceReducer,
  companyInfo: companyInfoReducer,
  common: commonReducer,
  customersearch: customerSearchReducer,
  plSummery: plSummeryReducer,
  settings: settingsReducer,
});

export default reducers;
