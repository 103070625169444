import axios from 'axios';
// export const NAME_APP = env.NAME_APP
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const CORE_URL = process.env.REACT_APP_Redirect_URL;

export const AuthInstance = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
});

export const CoreInstance = axios.create({
  baseURL: CORE_URL,
});
