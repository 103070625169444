import CryptoJS from 'crypto-js';

const privateKey = 'A3ADZiAlawip9sEgE5hbr204f8bRyvaF3TilAlsee87NAxVHpEd9jWr6W5zYQXk';

export function AESEncrypt(pureText) {
  var ciphertext = encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(pureText), privateKey).toString()
  );
  return ciphertext;
}

export function AESDecrypt(encryptedText) {
  var bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedText), privateKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
