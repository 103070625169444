import React, { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import sideLogo from 'assets/img/logo1.png';
import { MuiThemeProvider as NewMuiThemeProvider } from '@material-ui/core/styles';

import store from './redux/createStore';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
// core components
import { darkTheme } from './config/theme/index';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/material-dashboard-react.css?v=1.8.0';
import { loginCheck } from 'redux/actions/authActions';
import { validateSession, login, logout } from 'redux/actions/authAimmActions';

import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import { getCompany } from 'clients/api';
import { AuthInstance } from './providers/axiosInstance';
import { Button } from '@material-ui/core';
const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...Rest }) => (
  <Route
    {...Rest}
    render={props =>
      store.getState().authAimm.token ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const Admin = lazy(() => import('layouts/Admin.js'));
const Login = lazy(() => import('views/Authentication/Login'));
const SignUp = lazy(() => import('views/Authentication/Signup'));
const ForgotPassword = lazy(() => import('views/Authentication/ForgotPassword'));

function Auth0Component() {
  const [isLoading, setLoading] = useState(true);
  const [authValidatingError, setAuthValidatingError] = useState(false);
  const localValidateSession = async () => {
    const res = await AuthInstance.get(`/spa/auth/${process.env.REACT_APP_NAME_APP}/validate`);
    const encodedJwt = res.data?.token;

    return encodedJwt;
  };
  const validateSessionPage = () => {
    const token = Cookies.get('token');
    validateSession().then(res => {
      if (res) {
        setLoading(false);
        getCompany({ companyId: '0' }).then(
          x => {
            try {
              Cookies.set('companyId', x.data.companyId);
              Cookies.set('companyName', x.data.companyName);
              Cookies.set('userId', x.data.userId);
              if (Cookies.get('token')) {
                store.dispatch(loginCheck());
                store.dispatch({ type: 'SET_TOKEN', payload: Cookies.get('token') });
              }
            } catch (error) {
              login();
            }
          },
          error => {
            login();
          }
        );
      } else {
        //setLoading(false);
        login();
      }
    });
  };
  useEffect(() => {
    //validateSessionPage();
    localValidateSession()
      .then(encodedJwt => {
        store.dispatch({ type: 'SET_TOKEN', payload: encodedJwt });
        getCompany({ companyId: '0' }).then(
          x => {
            try {
              if (x.status == 200) {
                Cookies.set('companyId', x.data.companyId);
                Cookies.set('companyName', x.data.companyName);
                Cookies.set('userId', x.data.userId);
                store.dispatch(loginCheck());
                setTimeout(() => {
                  setLoading(false);
                }, 500);
              } else {
                setLoading(false);
                setAuthValidatingError(true);
                //logout();
              }
            } catch (error) {
              setLoading(false);
              setAuthValidatingError(true);
              //logout();
            }
          },
          error => {
            //console.log(error.response.status);
            //if (error.response.status == 401) {
            setLoading(false);
            setAuthValidatingError(true);
            //}
            // logout();
          }
        );
      })
      .catch(() => {
        login();
      });
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (authValidatingError) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <div className="card " style={{ textAlign: 'center', padding: 5, margin: 10 }}>
          <div>
            <img src={sideLogo} style={{ width: '250px' }} />
            <h3 className="">Your account is not associated with an active company.</h3>
          </div>
          <div className="row justify-content-between">
            <Button
              onClick={() => {
                window.location.href = 'https://my.aimmsystem.com';
              }}
              className="me-2"
              style={{ cursor: 'pointer' }}
              variant="contained"
              color="primary"
            >
              Subscribe to the AIMM Software
            </Button>
            <Button
              onClick={() => {
                logout();
              }}
              variant="outlined"
              style={{ cursor: 'pointer', marginLeft: 10 }}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Switch>
          {/* <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgotpassword" component={ForgotPassword} /> */}
          <PrivateRoute path="/admin" component={Admin} />
          <Redirect from="*" to="/admin/week-at-a-glance/in-house" />
        </Switch>
      </div>
    );
  }
}
ReactDOM.render(
  <Provider store={store}>
    <NewMuiThemeProvider theme={darkTheme}>
      <Router history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          {/* <Auth0Provider
            domain={process.env.REACT_APP_Auth0_Domain || 'aimm--dev.us.auth0.com'} //"aimm--dev.us.auth0.com" //"dev-dy36nz1le53ok2ak.us.auth0.com"
            clientId={process.env.REACT_APP_Auth0_ClientId || 'zqRSLahyRFikg1utaLCiPkKTDqGEiRSX'} //"2ExLeky7EanL4iuWSA2l6znjiw6p37SW"
            authorizationParams={{
              redirect_uri: process.env.REACT_APP_Redirect_URL,
            }}
          > */}
          <Auth0Component />

          {/* </Auth0Provider> */}
        </Suspense>
      </Router>
    </NewMuiThemeProvider>
    <ToastContainer autoClose={4000} position="top-right" />
  </Provider>,
  document.getElementById('root')
);
